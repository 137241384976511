import { CALL_API } from '../middleware/api';
import {
  CREATE_FORM_SUBMIT_REQUEST,
  CREATE_FORM_SUBMIT_SUCCESS,
  CREATE_FORM_SUBMIT_FAILURE,
  CREATE_SUBSCRIBE_REQUEST,
  CREATE_SUBSCRIBE_SUCCESS,
  CREATE_SUBSCRIBE_FAILURE,
  CREATE_EVENT_EMAIL_REQUEST,
  CREATE_EVENT_EMAIL_SUCCESS,
  CREATE_EVENT_EMAIL_FAILURE,
} from './types';

export const submitFormRequest = ({ successCallback = null, failureCallback = null, ...restPayload }) => ({
  [CALL_API]: {
    endpoint: 'sendMail',
    types: [CREATE_FORM_SUBMIT_REQUEST, CREATE_FORM_SUBMIT_SUCCESS, CREATE_FORM_SUBMIT_FAILURE],
    successCallback,
    failureCallback,
    useCache: false,
    body: restPayload,
  },
  type: 'SUBMIT_FORM_REQUEST',
});

export const subscribe = ({ successCallback = null, failureCallback = null, ...restPayload }) => ({
  [CALL_API]: {
    endpoint: 'subscribe',
    types: [CREATE_SUBSCRIBE_REQUEST, CREATE_SUBSCRIBE_SUCCESS, CREATE_SUBSCRIBE_FAILURE],
    successCallback,
    failureCallback,
    useCache: false,
    body: restPayload,
  },
  type: 'SUBSCRIBE_REQUEST',
});

export const submitEventFormRequest = ({ successCallback = null, failureCallback = null, emailContent }) => ({
  [CALL_API]: {
    endpoint: 'email/events',
    types: [CREATE_EVENT_EMAIL_REQUEST, CREATE_EVENT_EMAIL_SUCCESS, CREATE_EVENT_EMAIL_FAILURE],
    successCallback,
    failureCallback,
    useCache: false,
    body: emailContent,
  },
  type: 'SUBMIT_EVENT_FORM_REQUEST',
});
